import { useRef } from 'react'
import mean from 'lodash/mean'
import round from 'lodash/round'
import get from 'lodash/get'
import T from 'prop-types'
import { useMutation } from 'graphql-hooks'

import { upsertAssessmentScoringDataMutation } from '../queries'

const SLIDER_STEP = 10

/**
 * @param {Object} groupOverall
 * @param {number} cap
 * @return {number}
 */
function getOverallValue(groupOverall, cap) {
  return groupOverall.length
    ? round(mean(groupOverall) / SLIDER_STEP) * SLIDER_STEP
    : 0
}

/**
 * @param {Object} assessmentData
 * @param {Object} scoringDef
 * @param {Object} pillar
 * @param {Object} criterion
 * @param {number} partNumber
 * @return {Object} Scoring id and values
 */
function getScoringData(
  assessmentData,
  scoringDef,
  pillar,
  criterion,
  partNumber
) {
  const { scoring_values: values } =
    assessmentData.scoring.find(
      s =>
        s.pillar_key === pillar.key &&
        s.criterion_key === criterion.key &&
        s.part_number === partNumber
    ) || {}
  const scoringValues = scoringDef.reduce(
    (acc, scoringGroup) => ({
      ...acc,
      [scoringGroup.key]: scoringGroup.scores.reduce(
        (acc, score) => ({
          ...acc,
          [score.key]: get(values, `[${scoringGroup.key}][${score.key}]`, 0),
        }),
        {}
      ),
    }),
    {}
  )

  return {
    scoringValues,
  }
}

/**
 * @param {Object} capRule
 * @param {Object} scoringDef
 * @return {string}
 */
function getCapDescription(capRule, scoringDef) {
  const fallback = `“${capRule[0]}: ${capRule[1]}”`
  const scoringGroup = scoringDef.find(group => group.key === capRule[0])
  if (!scoringGroup) return fallback

  const scoringSlider = scoringGroup.scores.find(
    group => group.key === capRule[1]
  )
  return `“${scoringGroup.name}: ${
    scoringSlider ? scoringSlider.name : capRule[1]
  }”`
}

/**
 * @param {number} assessmentId
 * @param {Object} assessmentData
 * @param {Object} pillar
 * @param {Object} scoringDef
 * @param {Object} scoringRules
 * @param {Object} criterion
 * @param {number} partNumber
 * @param {Object} children
 * @return {Object}
 */
export default function ScoringWrapper({
  assessmentId,
  assessmentData,
  pillar,
  scoringDef,
  scoringRules,
  criterion,
  partNumber,
  children,
}) {
  const submitRef = useRef({
    onChange: false,
    onChangeCommitted: false,
  })

  const { scoringValues } = getScoringData(
    assessmentData,
    scoringDef,
    pillar,
    criterion,
    partNumber
  )

  const [upsertScoringData] = useMutation(upsertAssessmentScoringDataMutation)

  /**
   * @param {Object} values
   */
  async function handleScoreChange(values) {
    await upsertScoringData({
      variables: {
        assessmentId,
        pillarKey: pillar.key,
        criterionKey: criterion.key,
        partNumber,
        scoringValues: values,
      },
    })
  }

  return children({
    SLIDER_STEP,
    getOverallValue,
    submitRef,
    handleScoreChange,
    getCapDescription,
    scoringValues,
    scoringRules,
    scoringDef,
  })
}

ScoringWrapper.propTypes = {
  assessmentId: T.number.isRequired,
  assessmentData: T.object,
  pillar: T.object.isRequired,
  scoringDef: T.array.isRequired,
  criterion: T.object.isRequired,
  partNumber: T.number.isRequired,
}
