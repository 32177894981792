import React, { useState } from 'react'
import T from 'prop-types'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import {
  withStyles,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import { KeyboardArrowUp } from '@material-ui/icons'

const AssessmentScoringHeader = ({
  classes,
  isQuestionnaire = false,
  assessmentDef: { scoringGuide = [] },
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen(!isOpen)
  const { t } = useTranslation()

  const keyColumnClassName = classnames(classes.keyColumn, {
    [classes.questionnaireScoring]: isQuestionnaire,
  })

  return (
    <>
      <Typography variant="h2" color="primary" gutterBottom>
        {t('Scoring section')}
        <Button
          color="secondary"
          className={classes.expandButton}
          onClick={toggleOpen}
        >
          {t('Scoring guide')}
          <KeyboardArrowUp
            className={classnames(classes.icon, { [classes.open]: isOpen })}
          />
        </Button>
      </Typography>
      <Table className={classnames(classes.table, { [classes.hide]: !isOpen })}>
        <TableBody>
          {scoringGuide.map(({ range, description }) => (
            <TableRow key={`scoring_range_${range}`}>
              <TableCell className={keyColumnClassName}>{range}</TableCell>
              <TableCell>{t(description)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

AssessmentScoringHeader.propTypes = {
  classes: T.object.isRequired,
  isQuestionnaire: T.bool,
}

const styles = theme => ({
  hide: {
    display: 'none',
  },
  icon: {
    color: theme.palette.secondary.main,
    transition: 'transform 0.4s',
  },
  open: {
    transform: 'rotate(180deg)',
  },
  expandButton: {
    marginLeft: theme.spacing(2),
  },
  table: {
    margin: theme.spacing(1, 0, 6),
  },
  keyColumn: {
    maxWidth: theme.spacing(8),
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  questionnaireScoring: {
    padding: 0,
  },
})

export default withStyles(styles)(AssessmentScoringHeader)
